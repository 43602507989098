$(document).ready(function() {
  'use strict';

  var validator = $('#contact-form').validate({
    rules: {
      firstname: 'required',
      email: 'required',
      subject: 'required',
      message: 'required'
    },
    submitHandler: function(form, event) {
      event.preventDefault();
      var $form = $(form);

      $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize(),
        beforeSend: function(data) {
          $form.find(':input').prop('disabled', true);
        },
        success: function(data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
          } else {
            showMessageBox('danger', data.errors.message);
          }
        },
        failed: function(data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        },
        complete: function(data) {
          $form.find(':input').prop('disabled', false);
        }
      });
    }
  });
});

