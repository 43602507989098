'use strict';
/* Write here your custom javascript codes */

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

var Rewix = {
    trackAddToCart: function (a, b) { }
};

jQuery(document).ready(function () {
    App.init();
    initScrollBar();
    // App.initParallaxBg();
    // OwlCarousel.initOwlCarousel();
    // RevolutionSlider.initRSfullWidth();
    if (window.MasterSlider !== null) {
        initMasterSlide();
    }
    if ($.fn.owlCarousel) {
        initOwlCarousel();
    }

    $('.i-number').inputNumber({
        onChange: function (object) {
            $(object).closest('form').find('input[type="submit"]').prop('disabled', false);
        }
    });

    $('.btn-logout').on('click', function (event) {
        event.preventDefault();
        $(this).find('form').submit();
    });

    $('#subscribe-form').validate({
        rules: {
            username: 'required',
            password1: 'required',
            password2: {
                equalTo: '#password1'
            },
            firstname: 'required',
            lastname: 'required',
            cfpiva: 'required',
            i_country_id: 'required',
            cel_prefix: 'required',
            cel: 'required',
            clausola1: 'required'
        },
        errorPlacement: function (error, element) {
            if (element.attr('name') === 'clausola1') {
                error.insertAfter('#terms');
            }
            else {
                error.insertAfter(element);
            }
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            var $form = $(form);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        if (typeof dataLayer !== 'undefined' && dataLayer) {
                            pushGTMEventWithCallback(500, {
                                event: 'sign_up',
                                eventCallback: GTMCallback(function() {
                                    window.location = $form.attr('redirectsuccess');
                                })
                            })
                        }
                        else {
                            window.location = $form.attr('redirectsuccess');
                        }
                    }
                    else {
                        showMessageBox('danger', data.errors.message.length > 0 ? msg[data.errors.message] : msg.MSG_GENERIC_ERROR);
                    }
                },
                failed: function (data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function (data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });

    $('.cart-update').submit(function (event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            success: function (data) {
                rewixUpdateCart($(this));
                window.location.href = '/current/cart';
            },
            error: function (xhr, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                triggerUpdateCart();
            }
        });
    });

    $('.btn-cart-remove').on('click', function (event) {
        event.preventDefault();
        var data = {};
        data[$(this).attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $(this).attr('data-action'),
            data: data,
            success: function (data) {
                const row = $(this).closest('tr');
                rewixUpdateCart(row);
                window.location.href = '/current/cart';
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $(".burn-coupon-form").submit(function (event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('.carrier-form').change(function () {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            headers: { 'Accept': "application/json" },
            success: function (data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('select.sizes-selector').change(function () {
        showTableForColor(this.value, $(this).closest('.table-models'));
    });

    $('select.sizes-selector').each(function () {
        showTableForColor(this.value, $(this).closest('.table-models'));
    });

    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 120) {
            jQuery('.blog-sidebar').css("position", "fixed");
        }
        else {
            jQuery('.blog-sidebar').css("position", "static");
        }
    });

    $('.login-toggle-mode').click(function (event) {
        event.preventDefault();
        var $show = $($(this).data('show'));
        var $hide = $($(this).data('hide'));
        $hide.hide();
        $show.fadeIn();
    });

    if ($(window).width() < 481) {
        $('#filter-list .panel-collapse ').removeClass('in');
    }
});

function showTableForColor(color, parent) {
    if (parent) {
        $('table.sizes tbody tr', parent).hide();
        $('table.sizes tbody tr.' + color, parent).fadeIn();
    }
}

function preventNotNumericValues(e) {
    var charCode = (e.which) ? e.which : e.keyCode;

    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(charCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (charCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
        (charCode >= 35 && charCode <= 40)) {
        // let it happen, don't do anything
        return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (charCode < 48 || charCode > 57)) && (charCode < 96 || charCode > 105)) {
        e.preventDefault();
    }
}

function InputNumber(option) {
    return this.each(function () {
        var $this = $(this);

        $this.keydown(preventNotNumericValues);
        $this.keyup(function () {
            option.onChange($this.get(0));
        });

        $this.max = $this.attr('data-max');
        $this.min = $this.attr('data-min');

        $('<a class="quantity-button i-number-minus" href="#" role="button"><img src="/skins/myunderwear/img/myu-minus.svg" /></a>')
            .insertBefore($this)
            .on('click', function (event) {
                event.preventDefault();
                var val = parseInt($this.val());
                var min = $this.min || 0;
                if ($.isNumeric(val) && val > min) {
                    $this.val(val - 1);
                } else {
                    $this.val(0);
                }
                option.onChange($this.get(0));
            });
        $('<a class="quantity-button i-number-plus" href="#" role="button"><img src="/skins/myunderwear/img/myu-plus.svg" /></a>')
            .insertAfter($this)
            .on('click', function (event) {
                event.preventDefault();
                var val = parseInt($this.val());
                var max = $this.max || Number.MAX_VALUE;
                if (!$.isNumeric(val)) {
                    $this.val(0);
                } else if (val >= $this.min && val < max) {
                    $this.val(val + 1);
                }
                option.onChange($this.get(0));
            });
    });
}

$.fn.inputNumber = InputNumber;

function initMasterSlide() {
    if (typeof MasterSlider === 'undefined') {
        return;
    }
    var slider = new MasterSlider();

    slider.control('arrows');
    slider.control('thumblist', {
        autohide: false,
        dir: 'h',
        arrows: false,
        align: 'bottom',
        width: 180,
        height: 170,
        margin: 5,
        space: 5
    });

    slider.setup('masterslider', {
        width: 550,
        height: 550,
        space: 5,
        view: 'fade'
    });

    return slider;
}

function initOwlCarousel() {
    jQuery(document).ready(function () {
        var owl = jQuery(".owl-slider");
        owl.owlCarousel({
            items: [4],
            itemsDesktop: [1000, 3], //3 items between 1000px and 901px
            itemsDesktopSmall: [979, 2], //2 items between 901px
            itemsTablet: [600, 1], //1 items between 600 and 0;
            slideSpeed: 1000
        });

        // Custom Navigation Events
        jQuery(".next").click(function () {
            owl.trigger('owl.next');
        });
        jQuery(".prev").click(function () {
            owl.trigger('owl.prev');
        });
    });

    jQuery(document).ready(function () {
        var owl = jQuery('.owl-slider-home');
        owl.owlCarousel({
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                }
            },
            dots: false,
            pagination: false,
            autoplay: true,
            autoplayHoverPause: true,
            loop: true
        });
    });

    //Owl Slider v2
    jQuery(document).ready(function () {
        var owl = jQuery(".owl-slider-v2");
        owl.owlCarousel({
            items: 5,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 2
                },
                979: {
                    items: 3
                },
                1000: {
                    items: 4
                }
            },
        });
    });

    //Owl Slider v3
    jQuery(document).ready(function () {
        var owl = jQuery(".owl-slider-v3");
        owl.owlCarousel({
            items: 1,
            itemsDesktop: [1000, 1], //1 items between 1000px and 901px
            itemsDesktopSmall: [979, 1], //1 items between 901px
            itemsTablet: [600, 1], //1 items between 600 and 0;
            itemsMobile: [479, 1] //1 itemsMobile disabled - inherit from itemsTablet option
        });
    });

    jQuery(document).ready(function () {
        var owl = $(".owl-slider-v4");
        owl.owlCarousel({
            items: 4,
            loop: true,
            margin: 5,
            responsiveClass: true,
            nav: false,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 4,
                    loop: false
                }
            }
        });
        jQuery(".next").click(function () {
            owl.trigger('next.owl.carousel');
        });
        jQuery(".prev").click(function () {
            owl.trigger('prev.owl.carousel');
        });
    });

    jQuery(document).ready(function () {
        //Owl Slider
        jQuery(document).ready(function () {
            var owl = jQuery(".owl-twitter");
            owl.owlCarousel({
                items: [1]
            });

            // Custom Navigation Events
            jQuery(".next-v2").click(function () {
                owl.trigger('owl.next');
            });
            jQuery(".prev-v3").click(function () {
                owl.trigger('owl.prev');
            });
        });
    });
}

function showMessageBox(type, message, extra, onClose = null) {
    var boxType = 'alert-' + type;
    var content = '';
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    var messageBox = '<div class="alert ' + boxType + ' fade in">' + message + content + '</div>';
    $('div.alert-box').html(messageBox);
    setTimeout(function () {
        $('div.alert-box').html('');
        if (onClose) {
            onClose();
        }
    }, 10000);
}

function triggerUpdateCart() {
    $.getJSON('/restful/shopping/cart', function (data, textStatus, jqXHR) {
        console.log(data);
        updateCart(data.items, parseInt(data.total));
    });
}

function updateCart(products, total) {
    var prods = {};
    var product = null;
    var quantity = 0;

    for (var i = 0; i < products.length; i++) {
        product = products[i];
        prods[product.productId] = prods[product.productId] || {
            name: product.name,
            image: product.imageURL + '?w=320&h=320&crop=0&pad=1&fill=white',
            quantity: 0,
            price: product.price,
            brand: product.brand
        };

        quantity += product.quantity;
        prods[product.productId].quantity += product.quantity;
    }

    var lang = document.documentElement.lang;

    var code = '', price = 0;
    for (var key in prods) {
        price = prods[key].price;
        code += '<li>' +
            '<img src="' + prods[key].image + '" alt="' + prods[key].brand + ' ' + prods[key].name + '"/>' +
            '<div class="overflow-h">' +
            '<span>' + prods[key].brand + ' ' + prods[key].name + '</span>' +
            '<small>' + prods[key].quantity + ' x ' +
            (lang === 'en' ? price.format(2, 3, ',', '.') : price.format(2, 3, '.', ',')) + ' ' + currencySymbol +
            '</small>' +
            '</div>' +
            '</li>';
    }

    $('.product-list').mCustomScrollbar('destroy');
    $('.shop-badge .product-list').html(code);
    $('.shop-badge .badge').html(quantity);
    $('.shop-badge .subtotal-cost').html((lang === 'en' ? total.format(2, 3, ',', '.') : total.format(2, 3, '.', ',')) + ' ' + currencySymbol);

    // reinit scrollbar in the menu
    initScrollBar();
}

function initScrollBar() {
    $('.product-list').mCustomScrollbar({
        theme: 'minimal',
        scrollInertia: 300,
        scrollEasing: 'linear'
    });
}

var dropCookie = true;
var cookieValue = 'on';


function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, '', -1);
}

function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function (data) {
                resolve(data.results);
            },
            error: function () {
                resolve([]);
            }
        });
    });
}

function GTMCallback(callback) {
    return (containerId) => {
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    }
}

function pushGTMEventWithCallback(timeout, options) {
    const dataLayer = window.dataLayer ?? [];

    let callbackExecuted = false;
    dataLayer.push({
        ...options,
        ...(options.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        options.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (options.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}