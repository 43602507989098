$(document).ready(function() {
    'use strict';
    //recover password
    $('#password-recover-form').validate({
        rules : {
            recoveremail : 'email',
            recoverpassword : {
                required: true,
                minlength: 8
            },
            recoverpassword2: {
                equalTo: '#recoverpassword'
            }
        },
        submitHandler : function(form, event) {
            event.preventDefault();
            var email = $(form).find('#recoveremail').val();
            var password = $(form).find('#recoverpassword').val();

            $.ajax({
                type : $(form).attr('method'),
                url : $(form).attr('action'),
                data : {
                    email : email,
                    password : password
                },
                dataType : 'json',
                success : function(xdata) {
                    if (xdata.success) {
                        showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                    } else {
                        showMessageBox('danger', msg['MSG_RECOVEREMAILSENT_ERROR']);
                    }
                }
            });
        }
    });

    $.validator.messages.required = messages_requiredfield;

    $('#login-form').validate({
        rules: {
            username: 'required',
            password: 'required'
        }
    });

});