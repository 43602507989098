jQuery(document).ready(function() {
  'use strict';

  function checkPreferredLayout() {
    var layout = localStorage.getItem('catalogLayout');
    if (layout && layout === 'list') {
      $('#list-option').prop('checked', true).trigger('click');
    } else {
      layout = 'grid';
      $('#grid-option').prop('checked', true).trigger('click');
    }

    setCatalogLayout(layout);
  }

  function setCatalogLayout(layout) {
    if (layout === 'grid') {
      $('.list-layout').hide();
      $('.grid-layout').fadeIn();
    } else {
      $('.grid-layout').hide();
      $('.list-layout').fadeIn();
    }

    localStorage.setItem('catalogLayout', layout);
  }

  function reloadCatalog(url, push) {
    $('#catalog-wrapper').addClass('catalog-loading');
    $('#catalog-wrapper').load(url + ' #catalog-wrapper > div', function() {
      if (push) {
        window.history.pushState(null, document.title, url);
      }
      checkPreferredLayout();
      $('#catalog-wrapper').removeClass('catalog-loading');
      $('#filters-dropdown').show();
      $('.i-number').inputNumber({
        onChange: function(object) {
          $(object).closest('form').find('input[type="submit"]').prop('disabled', false);
        }
      });
    });
  }

  function updateCartCount(form) {
    var previous = parseInt($('#cart-button div.count').html());
    var addedProducts = 0;
    form.find('input[type=text]').each(function() {
      addedProducts += $(this).val() - $(this).data('qtyorig');
      $(this).data('qtyorig', $(this).val());
    });
    var newQuantity = previous + addedProducts;
    $('#cart-button div.count').html(newQuantity);
  }

  $(window).on('popstate', function(event) {
    reloadCatalog(window.location, false);
  });

  $('#catalog-wrapper').on('click', '.filter-by-block .catalog-filter', function(event) {
    event.preventDefault();
    var count = $('.filter-by-block li.active').length;
    if (count >= 8 && !$(this).parent().hasClass('active')) {
      showMessageBox('warning', general_toomanyfilters);
      $('.filter-by-block').focus();
    } else {
      var url = $(this).attr('data-href');
      reloadCatalog(url, true);
    }
  });

  $('#catalog-wrapper').on('click', '.current-filters a', function(event) {
    event.preventDefault();
    var url = $(this).attr('href');
    reloadCatalog(url, true);
  });

  $('#catalog-wrapper').on('click', '.toggle-subfilter', function(event) {
    event.preventDefault();
    $(this).parent('li').toggleClass('open');
  });

  $('#catalog-wrapper').on('click', '#toggle-filters-button', function(event) {
    event.preventDefault();
    $(this).find('.fa').toggleClass('fa-rotate-180x');
    $('#filters-dropdown').slideToggle();
  });

  $('#catalog-wrapper').on('click', '#layout-options input[type="radio"]', function(event) {
    setCatalogLayout(this.id.indexOf('grid') !== -1 ? 'grid' : 'list');
  });

  $('#catalog-wrapper, .shop-product').on('submit', '.add-to-cart-form', function(event) {
    event.preventDefault();
    var form = $(this);
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize(),
      headers: {'Accept': 'application/json'},
      success: function(data) {
        if (!data.success) {
          if (data.code === 'MIXED_INTANGIBLE') {
            showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
          }
          else if (data.code === 'MIXED_CART') {
            showMessageBox('danger', msg['MSG_MIXED_CART']);
          }
          else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
              showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
          }
          else if (data.code === 'LOGIN_REQUIRED') {
            location.href = '/' + $('html').attr('lang') + '/login';
          }
          else {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          }
        }
        else {
          rewixUpdateCart(form);
          triggerUpdateCart();
          showMessageBox('success', msg['MSG_ADDEDTOCART'], {
            content: '<a class="btn" href="/current/cart"><span class="fa fa-shopping-cart fa-spacing" />' +
            buttons_gocheckout + '</a>'
          });
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
      }
    });
  });

  checkPreferredLayout();

});
