$(document).ready(function () {
    "use strict";

    function submitUserUpdateForm(form, event) {
        $.ajax({
            type: form.attr("method"),
            url: form.attr("action"),
            data: form.serialize(),
            dataType: "json",
            beforeSend: function (data) {
                form.find(":input").prop("disabled", true);
            },
            success: function (data) {
                if (data.success) {
                    // TODO customize message
                    showMessageBox("success", "Save successful");
                } else {
                    showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
                }
            },
            failed: function (data) {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            },
            complete: function (data) {
                form.find(":input").prop("disabled", false);
                location.reload();
            },
        });
    }

    $("#user-update-data-form").validate({
        rules: {
            firstname: "required",
            lastname: "required",
            company: "required",
            notes: "required",
            cel: "required",
            cel_prefix: "required",
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            submitUserUpdateForm($(form), event);
        },
    });

    $("#user-update-dispatch-form").validate({
        rules: {
            d_addressee: "required",
            d_country_id: "required",
            d_street: "required",
            d_number: "required",
            d_cap: "required",
            d_city: "required",
            d_prov: "required",
            d_cel_prefix: "required",
            d_cel: "required",
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            submitUserUpdateForm($(form), event);
        },
    });

    $("#user-update-invoice-form").validate({
        rules: {
            i_addressee: "required",
            cfpiva: "required",
            i_country_id: "required",
            i_street: "required",
            i_number: "required",
            i_cap: "required",
            i_city: "required",
            i_prov: "required",
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            submitUserUpdateForm($(form), event);
        },
    });

    $("#password-change-form").validate({
        rules: {
            password1: "required",
            password2: {
                equalTo: "#password1",
            },
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            $.ajax({
                type: $(form).attr("method"),
                url: $(form).attr("action"),
                data: $(form).serialize(),
                dataType: "json",
                beforeSend: function (data) {
                    $(form).find(":input").prop("disabled", true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox("success", data.data);
                    } else {
                        showMessageBox("danger", data.data);
                    }
                },
                complete: function (data) {
                    $(form).find(":input").prop("disabled", false);
                },
            });
        },
    });
});

$(document).ready(function () {
    "use strict";

    $.fn.showControls = function () {
        return this.each(function () {
            var $this = $(this);
            var controls = $this.attr("data-target");

            $(controls).show();
            $this.addClass("selected");
            $this.find(".order-row-indicator").addClass("fa-rotate-90");
        });
    };

    $.fn.hideControls = function () {
        return this.each(function () {
            var $this = $(this);
            var controls = $this.attr("data-target");

            $(controls).hide();
            $this.removeClass("selected");
            $this.find(".order-row-indicator").removeClass("fa-rotate-90");
        });
    };

    $(".order-row").on("click", function (event) {
        var $this = $(this);
        if ($this.hasClass("selected")) {
            $this.hideControls();
        } else {
            // remove selected class from other rows
            $this.parent().find(".order-row.selected").hideControls();
            $this.showControls();
            $("html, body").animate(
                {
                    scrollTop: $this.position().top,
                },
                200
            );
        }
    });

    $(".delete-order").on("click", function (event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: "/restful/shopping/checkout/payment/cancel",
            data: { orderId: $this.attr("data-order-id") },
            dataType: "json",
            success: function (data) {
                if (data.success) {
                    window.location.reload();
                } else {
                    alert("Cannot cancel this order.");
                }
            },
            failed: function (data) {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            },
        });
    });

    $(".pay-order").on("click", function (event) {
        var $this = $(this);
        $.ajax({
            type: "POST",
            url: "/restful/shopping/checkout/payment/initialize",
            data: { orderId: $this.attr("data-order-id") },
            dataType: "json",
            success: function (data) {
                if (data.success) {
                    window.location = "/current/checkout/payment";
                } else {
                    alert("Cannot pay this order.");
                }
            },
            failed: function (data) {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            },
        });
    });

    $(".show-return-form").click(function (event) {
        event.preventDefault();
        var $this = $(this);
        var target = $this.attr("data-target");

        $(target).show();
    });

    $(".new-return-request form").submit(function (event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr("method"),
            url: $(this).attr("action"),
            data: $(this).serialize(),
            dataType: "json",
            beforeSend: function (data) {
                $(this).prop("disabled", true);
            },
            success: function (data) {
                if (data.success) {
                    //TODO translate
                    showMessageBox(
                        "success",
                        "Return Request created successfully"
                    );
                } else {
                    showMessageBox("danger", data.errors.message);
                }
            },
            failed: function (data) {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            },
            complete: function (data) {
                $(this).prop("disabled", false);
            },
        });
    });

    $("#user-update-consents-form").on('submit', function (event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr("method"),
            url: $(this).attr("action"),
            data: JSON.stringify({
                userConsents: $(this).find('input').toArray().reduce((arr, input) => {
                    if (input.type != 'submit') {
                        arr.push({
                            consentId: input.name,
                            value: input.checked
                        });
                    }
                    return arr;
                }, [])
            }),
            dataType: "json",
            headers: {
                "Content-Type": "application/json"
            },
            success: function (data) {
                if (data.status) {
                    showMessageBox('success', msg['MSG_CONTACT_PREFERENCES_UPDATED'], '', () => {
                        window.location.reload();
                    });
                }
                else {
                    showMessageBox("danger", data.errors.message);
                }
            },
            failed: function (data) {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            },
        });
    });
});
