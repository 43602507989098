'use strict';
/* Write here your custom javascript codes */
jQuery(document).ready(function () {
    $('.btn-checkout-next').on('click', function (event) {
        var $btn = $(this);
        var $form = $($btn.data('validate-form'));
        if ($form.length > 0) {
            event.preventDefault();
            $form.submit();
        }
    });

    $('#dispatch-address-form').validate({
        rules: {
            addressee: 'required',
            country_id: 'required',
            street: 'required',
            number: 'required',
            cap: 'required',
            city: 'required',
            prov: 'required',
            cel_prefix: 'required',
            cel: 'required'
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            submitAddressEditForm($(form));
        }
    });

    $('#billing-address-form').validate({
        rules: {
            addressee: 'required',
            cfpiva: 'required',
            country_id: 'required',
            street: 'required',
            number: 'required',
            cap: 'required',
            city: 'required',
            prov: 'required',
            cel_prefix: 'required',
            cel: 'required'
        },
        submitHandler: function (form, event) {
            event.preventDefault();
            if (!$('#clausola1').is(':checked')) {
                showMessageBox('danger', msg['ERR_PRIVACY']);
            }
            else {
                submitAddressEditForm($(form));
            }
        }
    });

    function submitAddressEditForm(form) {
        var href = form.data('nextstep');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            headers: { 'Accept': "application/json" },
            success: function (data) {
                if (data.success) {
                    if (data.data == 'VAT_CHANGED') {
                        href = '//' + location.host + location.pathname + '?warning=msg.order.vat.changed';
                    }

                    if (typeof dataLayer !== 'undefined' && dataLayer && form.attr('id') == 'dispatch-address-form') {
                        dataLayer.push({ ecommerce: null });
                        pushGTMEventWithCallback(500, {
                            event: 'add_shipping_info',
                            ecommerce: window.ecommerce,
                            eventCallback: GTMCallback(function() {
                                window.location.href = href;
                            })
                        });
                    }
                    else {
                        window.location.href = href;
                    }
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    function loadProvinces(countryId) {
        return new Promise((resolve) => {
            $.ajax({
                method: 'GET',
                url: `/restful/countries/provinces?countryId=${countryId}`,
                success: function (data) {
                    resolve(data.results);
                },
                error: function () {
                    resolve([]);
                }
            });
        });
    }

    $('[name*="country"]').on('change', updateProvinces);
    updateProvinces();

    function updateProvinces() {
        $('[name*="prov"]').each(function () {
            const $this = $(this);
            const country = $this.closest('form').find('[name*="country"]');

            if (country.length > 0) {
                loadProvinces(country.val()).then(function (provinces) {
                    if (provinces.length > 0) {
                        const id = $this.attr('id');
                        const classes = $this.attr('class');
                        const name = $this.attr('name');
                        const type = $this.attr('type');
                        const value = $this.val();
                        const placeholder = $this.attr('placeholder');
                        let options = [];
                        let hasSelectedValue = false;

                        for (let i = 0; i < provinces.length; i++) {
                            const province = provinces[i];
                            const selected = (province.code == value) ? 'selected' : '';
                            options.push(`<option value="${province.code}" ${selected}>${province.name} (${province.code})</option>`);
                            hasSelectedValue = hasSelectedValue || (province.code == value);
                        }
                        if (!hasSelectedValue) {
                            options[0].replace('  ', ' selected');
                        }
                        $this.replaceWith(`
                          <select id="${id}" class="${classes}" name="${name}" data-type="${type}" data-placeholder="${placeholder}" data-value="${value}" required aria-required="true">
                              ${options.join('\n')}
                          </select>
                      `);
                    }
                    else if ($this.is('select')) {
                        const id = $this.attr('id');
                        const classes = $this.attr('class');
                        const name = $this.attr('name');
                        const type = $this.data('type');
                        const value = $this.data('value');
                        const placeholder = $this.data('placeholder');
                        $this.replaceWith(`<input id="${id}" class="${classes}" type="${type}" name="${name}" value="${value}" placeholder="${placeholder}" required aria-required="true"/>`);
                    }
                });
            }
        });
    }

    $('#booking-btn').on('click', function(event) {
        const btn = this;

        if (typeof dataLayer !== 'undefined' && dataLayer) {
            event.preventDefault();
            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('Booking'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });

    $('#cod-btn').on('click', function(event) {
        const btn = this;

        if (typeof dataLayer !== 'undefined' && dataLayer) {
            event.preventDefault();
            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('COD'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });
});
